
import { Watch, Mixins, Component, Vue, Ref, Provide, ProvideReactive } from "vue-property-decorator";
import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";

import TongueExplain from "./components/explain.vue";
import TongueSymptom from "./components/symptom.vue";

@Component({
    inject: [],
    components: {
        TongueExplain,
        TongueSymptom
    }
  })
export default class Tongue extends Mixins(Resize, TablePagination) {
    currentTab = "0";
    tabList = [{ name: "0", id: 0, label: "症状" }, { name: "1", id: 1, label: "舌诊名词解释" }];
}
