  
    import { Emit, Component, Mixins, Ref, Prop, InjectReactive, Provide, ProvideReactive } from "vue-property-decorator";
    import ETable from "@/components/ETable.vue";
  
    import TablePagination from "@/mixins/tablePagination";
    import Resize from "@/mixins/resize";

    import { tongueExplainList, tongueExplainTypeList, tongueExplainEdit, tongueExplainDel } from "@/api/tongue";
    import { ElForm } from "element-ui/types/form";

    import { formatTextarea } from "@/utils/common"
  
    @Component({
      components: {
        ETable,
      }
    })
    export default class TongueSymptom extends Mixins(Resize, TablePagination) {
      @Ref("formRef") formRef !: ElForm;
      
      queryForm = {
        typeId: "",
        name: "",
      };
      typeOptions = [
      ];

      catOptions = [];
      

      tableData: Array<any> = [{ id: 0 }];
      tableLoading = false;
      columns = [
        { label: "类型", prop: "tongueTypeName" },
        { label: "名称", prop: "typeName" },
        { label: "详细介绍", prop: "details" },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "操作", prop: "action"},
    ]
  
    dialogForm = {
      id: "",
      tongueTypeId: "",
      typeNameId: "",
      details: "",
      remark: "",
    };

    dialogFormRules = {
      tongueTypeId: { required: true, message: "请选择舌体类型" },
      typeNameId: { required: true, message: "请选择分类名称" },
      details: { required: true, message: "请选择详细介绍" },
    };
      dialogVisible = false;
      dialogTableList: Array<any> = [];
  
      mounted() {
        this.windowResize(310);
        this.getTongueExplainTypeList(0, "typeOptions");
        this.getData();
      }
      formatTextarea(content) {
        return formatTextarea(content)
      }

      getTongueExplainTypeList(parentId = 0, key: string) {
        return tongueExplainTypeList(parentId).then((res) => {
          this[key] = res.data;
        })
      }

      search() {
        this.resetCurrent();
        this.getData();
      }
  
      getData() {
        this.tableLoading = true;
        tongueExplainList({
          ...this.queryForm,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
        }).then((res) => {
          this.tableData = res.data.list;
          this.itemTotal = res.data.total;
        }).finally(() => {
          this.tableLoading = false;
        })
      }

      tongueTypeChange($event) {
        if (!$event) return;
        this.getTongueExplainTypeList($event, "catOptions")
      }

      del(row) {
        this.$confirm(`是否删除【${row.tongueTypeName}】名称解释`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          tongueExplainDel(row.id).then(() => {
            this.$message.success("操作成功");
            this.getData();
          })
        })
      }

      add() {
        this.dialogVisible = true;
        Object.assign(this.dialogForm, { id: "" });
      }

      edit(row) {
        this.dialogVisible = true;
        this.getTongueExplainTypeList(row.tongueTypeId, "catOptions").then(() => {
          Object.assign(this.dialogForm, row);
        })
      }

      save() {
        this.formRef?.validate().then(() => {
          tongueExplainEdit({ ...this.dialogForm }).then((res) => {
            console.log("res", res);
            this.$message.success("操作成功");
            this.cancel();
            this.getData();
          })
        })
      }

      cancel() {
        this.handleClose();
      }
  
      handleClose() {
        this.dialogForm.id = "";
        this.formRef?.resetFields();
        this.dialogVisible = false;
      }
  
    }
  