  
    import { Component, Mixins, Ref } from "vue-property-decorator";
    import ETable from "@/components/ETable.vue";
    import RichText from "@/components/rich-text.vue";
  
    import TablePagination from "@/mixins/tablePagination";
    import Resize from "@/mixins/resize";

    import { tongueProfileEdit, tongueProfileList } from "@/api/tongue";
    import { ElForm } from "element-ui/types/form";

    import { cloneDeep } from "lodash";

    import { formatTextarea } from "@/utils/common"
  
    @Component({
      components: {
        ETable,
        RichText
      }
    })
    export default class TongueExplain extends Mixins(Resize, TablePagination) {
      @Ref("formRef") formRef !: ElForm;
  
      toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'color': []}], // dropdown with defaults from theme
        [{'align': []}],
        [{'clean': '清除'}], // remove formatting button
        ['image']
      ]

      tableData: Array<any> = [];
      tableLoading = false;
      columns = [
        { label: "舌诊症状", prop: "tongueSymptomName" },
        { label: "建议（通用性别）", prop: "suggestion" },
        { label: "症状（通用性别）", prop: "symptomInfo" },
        { label: "原因（通用性别）", prop: "reason" },
        { label: "预防疾病（通用性别）", prop: "preventDisease" },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "操作", prop: "action"},
    ]
  
      dialogVisible = false;
      dialogForm = {
        id: "",
        tongueSymptomName: "", // 症状名称
        preventDisease: "", // 预防疾病
        reason: "", // 原因
        suggestion: "", // 原因
        symptomDetail: "", // 症状秒速
        symptomInfo: "", // 症状
        symptomInfoM: "",
        reasonM: "",
        preventDiseaseM: "",
        symptomInfoW: "",
        reasonW: "",
        preventDiseaseW: "",
        minoritySymptomInfo: "",
        minorityReason: "",
        minorityPreventDisease: "",
        suggestionM: "",
        suggestionW: "",
        minoritySuggestion: "",
        symptomDetailM: "",
        symptomDetailW: "",
        minoritySymptomDetail: "",
      };

      activeName = "first";
  
      mounted() {
        this.windowResize(220);
        this.getData();
      }

      formatTextarea(content) {
        return formatTextarea(content)
      }
  
      getData() {
        tongueProfileList({}).then((res) => {
          this.tableData = res.data;
        })
      }

      edit(row) {
        this.dialogVisible = true;
        const {
          id,
          ...otherInfo
          // tongueSymptomName, // 症状名称
          // preventDisease, // 预防疾病
          // reason, // 原因
          // suggestion, // 原因
          // symptomDetail, // 症状秒速
          // symptomInfo, // 症状 
        } = row;
        Object.assign(this.dialogForm, { id, ...otherInfo });
        console.log(this.dialogForm)
      }

      save() {
        const params = cloneDeep(this.dialogForm);
        tongueProfileEdit(params).then(() => {
          this.$message.success("操作成功");
          this.cancel();
          this.getData();
        })
      }

      cancel() {
        this.handleClose();
      }
  
      handleClose() {
        this.dialogForm.id = "";
        this.formRef?.resetFields();
        this.dialogVisible = false;
        this.activeName = "first";
      }
  
    }
  